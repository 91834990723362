import React, { useState } from 'react'
import { Box, Modal, Typography, Button, TextField, Stack, CircularProgress } from '@mui/material'
import { useObjectStore } from '../../stores/useObjectStore'
import { createProject } from '../../services/api'
import { useStateStore } from '../../stores/useStateStore'
import * as XLSX from 'xlsx'
import ErrorBox from '../ErrorBox'
import AlertBox from '../AlertBox'
import { useNavigate } from 'react-router-dom'
import initialData from '../../initial-data'

const initialLayout = initialData.layout

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40%',
    transform: 'translate(-50%, -50%)',
    // width: "40%",
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    ':focus-visible': { outline: 'none' }
}

const NewProjectModal = ({ open, handleClose, finalLayout }) => {

    const [name, setName] = useState('')
    const [tags, setTags] = useState(0)
    const [titles, setTitles] = useState([])
    const [firstRow, setFirstRow] = useState([])
    const { errorMessage, setErrorMessage, loading, setLoading, alert, setAlert } = useStateStore()

    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)

    const navigate = useNavigate()

    const handleDrop = (event) => {
        event.preventDefault()
        const droppedFile = event.dataTransfer.files[0]
        if (droppedFile && droppedFile?.type === "text/csv" || droppedFile?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setFile(droppedFile)
            setError(null)
            readFileContent(droppedFile)
        } else {
            setError("Only CSV, or Excel files are allowed")
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]
        if (selectedFile && selectedFile?.type === "text/csv" || selectedFile?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setFile(selectedFile)
            setError(null)
            readFileContent(selectedFile)
        } else {
            setError("Only CSV, or Excel files are allowed")
        }
    }

    const readFileContent = (file) => {
        const reader = new FileReader();

        if (file?.type === "text/csv") {
            reader.onload = (event) => {
                const csvContent = event.target.result;
                const lines = csvContent.split("\r\n");

                if (lines.length > 0) {
                    const headers = lines[0].split(",");
                    setTitles(headers)

                    const rows = lines.slice(1).map(line => line.split(","));
                    setTags(rows.length - 1)
                    const firstRow = rows[0]
                    setFirstRow(firstRow)
                }
            }
            reader.readAsText(file);
        } else if (file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                if (json.length > 0) {
                    const headers = json[0];
                    setTitles(headers)
                    // console.log("Excel Headers:", headers);

                    const rows = json.slice(1);
                    setTags(rows.length - 1)
                    const firstRow = rows[0]
                    setFirstRow(firstRow)
                    // console.log("Excel File Content:", rows);
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleNewProject = () => {
        setErrorMessage('')
        setLoading(true)
        const formData = new FormData()
        formData.append('name', name)
        formData.append('number_of_tags', tags)
        formData.append('layout', JSON.stringify(initialLayout))
        formData.append('titles', JSON.stringify(titles))
        formData.append('first_record', JSON.stringify(firstRow))
        formData.append('data_file', file)

        createProject(formData)
            .then((res) => {
                setLoading(false)
                if (res.status != 201) {
                    setErrorMessage(res?.response?.data?.message)
                    return
                }
                setAlert(true)
                setTimeout(() => {
                    setAlert(false)
                    navigate(`/design/${res?.data?.project_id}`)
                }, 1000)
            })
            .catch((err) => {
                setLoading(false)
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message)
                    return
                }
                if (err.request) {
                    setErrorMessage(err?.message)
                    return
                }
                console.error(err)
            })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="upload-modal-title"
            aria-describedby="upload-modal-description"
        >
            <Box sx={style} display={'flex'} flexDirection={'column'} alignItems={'end'} >
                <Box width={'100%'}>
                    <ErrorBox />
                    <Typography variant="h6" mb={1}>New Project</Typography>
                    <Box>
                            <Typography variant="subtitle1">Project Name</Typography>
                            <TextField
                                value={name}
                                name='name'
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                variant="outlined"
                                size='small'
                                sx={{ mb: 2 }}
                                type='text'
                                placeholder='Enter project name'
                            />
                        </Box>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'end'} >
                        <Box width={'100%'}>
                            <Typography variant="subtitle1">Upload Data</Typography>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Only CSV files are allowed
                            </Typography>
                            <Box
                                onDrop={handleDrop}
                                onDragOver={(e) => e.preventDefault()}
                                sx={{
                                    border: '2px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    mt: 2,
                                    borderRadius: 1,
                                    p: 5
                                }}
                            >
                                <Typography variant="body2">
                                    Drag & Drop your file here or
                                </Typography>
                                <Button variant="contained" component="label" sx={{ mt: 5, textTransform: 'capitalize', color: 'white' }}>
                                    Browse File
                                    <input type="file" hidden onChange={handleFileChange} accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                </Button>
                            </Box>
                            {error && (
                                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                    {error}
                                </Typography>
                            )}
                            {file && (
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    Selected file: {file.name}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    {loading ? <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }}
                        disabled
                    >
                        <CircularProgress size={25} />
                    </Button>
                        :
                        <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }}
                            onClick={handleNewProject}
                        >Create</Button>}
                </Box>
                <AlertBox message={"Project created successfully"} />
            </Box>
        </Modal>
    )
}

export default NewProjectModal
