import React, { useState } from 'react'
import { Alert, AlertTitle, Box, Button, CircularProgress, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material'
import BgPlaceholder from '../components/BgPlaceholder'
import { useNavigate } from 'react-router-dom'
import { createUser } from '../services/api'
import { useStateStore } from '../stores/useStateStore'
import ErrorBox from '../components/ErrorBox'
import AlertBox from '../components/AlertBox'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

function Signup() {

    const navigate = useNavigate()
    const [signupInfo, setSignupInfo] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const {errorMessage, setErrorMessage, loading, setLoading, alert, setAlert} = useStateStore()

    const handleChange = (e) => {
        const { name, value } = e.target
        setSignupInfo({
            ...signupInfo,
            [name]: value
        })
    }

    const handleSignup = () => {
        setErrorMessage('')
        setLoading(true)
        createUser(signupInfo)
            .then((res) => {
                setLoading(false)
                if (res.status != 201) {
                    setErrorMessage(res?.response?.data?.message)
                    return
                }
                localStorage.setItem("email", res.data.email)
                localStorage.setItem("access_token", res.data.access_token)
                setAlert(true)
                setTimeout(() => {
                    setAlert(false)
                }, 3000)
                navigate('/')
            })
            .catch((err) => {
                setLoading(false)
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message)
                    return
                }
                if (err.request) {
                    setErrorMessage(err?.message)
                    return
                }
                console.error(err)
            })
    }

    return (
        <Stack direction={'row'}>
            <Box width={{ xs: '100%', lg: '50%' }} minHeight={'100vh'} display={'flex'} alignItems={'center'}>
                <Box width={{ xs: '80%', lg: '50%' }} mx={'auto'} >
                    <ErrorBox />
                    <Typography fontWeight={''} variant='h2' fontSize={{ xs: 40, lg: 30 }}>
                        Get Started Now
                    </Typography>
                    <Typography mt={3}>Email Address *</Typography>
                    <TextField
                        id="outlined-basic-email"
                        label=""
                        variant="outlined"
                        size='small'
                        fullWidth
                        placeholder='Enter your email'
                        name='email'
                        value={signupInfo?.email}
                        onChange={handleChange}
                    />
                    <Typography mt={3} >Password *</Typography>
                    <TextField
                        id="outlined-basic-pass"
                        label=""
                        variant="outlined"
                        size='small'
                        fullWidth
                        placeholder='Enter your password'
                        name='password'
                        value={signupInfo?.password}
                        onChange={handleChange}
                        type={showPassword ? 'text' :'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {showPassword ?
                                        <IconButton onClick={() => setShowPassword(false)}>
                                            <VisibilityOffOutlinedIcon />
                                        </IconButton>
                                        :
                                        <IconButton onClick={() => setShowPassword(true)}>
                                            <VisibilityOutlinedIcon />
                                        </IconButton>}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography mt={3} >Confirm Password *</Typography>
                    <TextField
                        id="outlined-basic-pass-2"
                        label=""
                        variant="outlined"
                        size='small'
                        fullWidth
                        placeholder='Enter your password'
                        name='confirm_password'
                        value={signupInfo?.confirm_password}
                        onChange={handleChange}
                        type={showConfirmPassword ? 'text' :'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {showConfirmPassword ?
                                        <IconButton onClick={() => setShowConfirmPassword(false)}>
                                            <VisibilityOffOutlinedIcon />
                                        </IconButton>
                                        :
                                        <IconButton onClick={() => setShowConfirmPassword(true)}>
                                            <VisibilityOutlinedIcon />
                                        </IconButton>}
                                </InputAdornment>
                            ),
                        }}
                    />
                    {!loading ? <Button
                        variant='contained'
                        sx={{ paddingX: '3rem', textTransform: 'capitalize', width: '100%', mt: 5, mb: 5, color: 'white' }}
                        onClick={handleSignup}
                    >
                        Sign up
                    </Button>
                        :
                        <Button
                            variant='contained'
                            sx={{ paddingX: '3rem', textTransform: 'capitalize', width: '100%', mt: 5, mb: 5, color: 'white' }}
                            disabled
                        >
                            <CircularProgress size={25} />
                        </Button>}
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Stack direction={'row'} spacing={1}>
                            <Typography>Have an account?</Typography>
                            <Typography><Link href="/login">Sign In</Link></Typography>
                        </Stack>
                    </Box>
                </Box>
            </Box>
            <BgPlaceholder />
            <AlertBox message={"Signup successful"} />
        </Stack>
    )
}

export default Signup