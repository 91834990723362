import React, { useState, useCallback, useEffect } from "react"
import DropZone from "../components/DropZone"
import TrashDropZone from "../components/TrashDropZone"
import SideBarItem from "../components/SideBarItem"
import Row from "../components/Row"
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout
} from "../helpers"

import { SIDEBAR_ITEMS, SIDEBAR_ITEM, COMPONENT, COLUMN } from "../constants"
import shortid from "shortid"
import { Box, Button, CircularProgress, Skeleton, Stack, styled, Typography } from "@mui/material"
import EditSidebar from "../components/EditSidebar"
import { useObjectStore } from "../stores/useObjectStore"
import { useParams } from "react-router-dom"
import { getProject, updateLayout } from "../services/api"
import { useStateStore } from "../stores/useStateStore"
import AlertBox from "../components/AlertBox"
import ErrorBox from "../components/ErrorBox"



const Design = () => {
  const { layout, setLayout, components, setComponents, setLabelAndData, canvasSize, setSelectedComponent } = useObjectStore()
  const [finalLayout, setFinalLayout] = useState([])
  const [open, setOpen] = useState(true)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [titles, setTitles] = useState([])
  const [firstRow, setFirstRow] = useState([])
  const [sidebarItems, setSidebarItems] = useState([])
  const [saving, setSaving] = useState(false)
  const { setErrorMessage, loading, setLoading, setAlert } = useStateStore()
  const params = useParams()

  console.log("Layout: ", layout)
  const sidebarComponents = [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Blank: Blank",
        content: "Blank: Blank",
        componentType: "Blank",
        width: 300,
        height: 20,
        // name: "Blank 1",
        label: "",
        data: "none",
      }
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Text: Text",
        content: "Text: Text",
        componentType: "Text",
        width: 300,
        height: 20,
        // name: "Text 1",
        label: "Text",
        data: "none",
        fontSize: 13
      }
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Image: Image",
        content: "Image: Image",
        componentType: "Image",
        width: 150,
        height: 150,
        // name: "Image 1",
        label: "Image",
        data: "none",
      }
    }
  ]

  useEffect(() => {
    setErrorMessage('')
    setLoading(true)
    getProject(params.id)
      .then((res) => {
        if (res.status != 200) {
          setErrorMessage(res?.response?.data?.message)
          return
        }
        setLayout(JSON.parse(res?.data?.project?.layout))
        setTitles(JSON.parse(res?.data?.project?.titles))
        setFirstRow(JSON.parse(res?.data?.project?.first_record))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          setErrorMessage(err?.response?.data?.message)
          return
        }
        if (err.request) {
          setErrorMessage(err?.message)
          return
        }
        console.log(err)
      })

  }, [])

  useEffect(() => {
    const labeledData = {}
    const sidebarItems = titles.map((title, index) => {
      const formattedTitle = title.toLowerCase()
      labeledData[formattedTitle] = firstRow[index]
      return {
        id: shortid.generate(),
        type: SIDEBAR_ITEM,
        component: {
          type: `Text: ${formattedTitle}`,
          content: `Text: ${formattedTitle}`,
          componentType: "Text",
          width: 300,
          height: 20,
          // name: "Text 1",
          label: `${formattedTitle[0].toUpperCase()}${formattedTitle.slice(1)}: `,
          data: formattedTitle,
          fontSize: 13
        }
      }
    })

    setSidebarItems(sidebarItems)
    setLabelAndData(labeledData)
  }, [titles])


  useEffect(() => {
    const deepLayout = layout[0]['children'][0]['children']
    const newLayout = []
    for (let component in deepLayout) {
      if (components[deepLayout[component]?.id]) {
        const newObj = { ...deepLayout[component], ...components[deepLayout[component]?.id] }
        newLayout.push(newObj)
      }
    }
    const backendLayout = {
      type: "canvas",
      id: "canvas0",
      height: canvasSize?.height,
      width: canvasSize?.width,
      children: newLayout
    }
    setFinalLayout(backendLayout)
  }, [layout, components, canvasSize])

  const handleDropToTrashBin = useCallback(
    (dropZone, item) => {
      const splitItemPath = item.path.split("-")
      setLayout(handleRemoveItemFromLayout(layout, splitItemPath))
    },
    [layout]
  )

  const handleDrop = useCallback(
    (dropZone, item) => {

      const splitDropZonePath = dropZone.path.split("-")
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-")

      const updateEditAtrr = (newComponent) => {
        setLayout(layout.map(row => {
          return {
            ...row,
            children: row.children.map(column => {
              column.children.push({ ...newComponent, name: `${newComponent?.componentType?.toLowerCase()}_${parseInt(splitDropZonePath[splitDropZonePath.length - 1]) + 1}` })
              return column
            })
          }
        }))
      }

      const newItem = { id: item.id, type: item.type }
      if (item.type === COLUMN) {
        newItem.children = item.children
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: shortid.generate(),
          ...item.component
        }
        const newItem = {
          ...newComponent,
          id: newComponent.id,
          type: COMPONENT
        }
        setComponents({
          ...components,
          [newComponent.id]: newComponent
        })
        setLayout(
          handleMoveSidebarComponentIntoParent(
            layout,
            splitDropZonePath,
            newItem
          )
        )
        updateEditAtrr(newComponent)
        setSelectedComponent(newComponent.id)
        return
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-")
      const pathToItem = splitItemPath.slice(0, -1).join("-")

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
          )
          return
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        )
        return
      }

      // 3. Move + Create
      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        )
      )
    },
    [layout, components]
  )

  const handleUpdateLayout = () => {
    setErrorMessage('')
    setSaving(true)
    updateLayout(params.id, JSON.stringify(layout))
      .then((res) => {
        setSaving(false)
        if (res.status != 200) {
          setErrorMessage(res?.response?.data?.message)
          return
        }
        setAlert(true)
        setTimeout(() => {
          setAlert(false)
        }, 1000)
      })
      .catch((err) => {
        setSaving(false)
        if (err.response) {
          setErrorMessage(err?.response?.data?.message)
          return
        }
        if (err.request) {
          setErrorMessage(err?.message)
          return
        }
        console.error(err)
      })
  }

  const renderRow = (row, currentPath) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
      />
    )
  }

  // dont use index for key when mapping over items
  // causes this issue - https://github.com/react-dnd/react-dnd/issues/342


  const CustomStack = styled(Stack)`
  width: 20%
  max-height: 85vh
  overflow-y: scroll

  ::-webkit-scrollbar {
    width: 8px
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1
  }

  ::-webkit-scrollbar-thumb {
    background: #d1d1d1
    border-radius: 10px
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2
  }
`

  return (
    <div className="body">
      <CustomStack width={'20%'}>
        <Box p={3} borderRadius={3} bgcolor={'white'} >
          <Typography variant="h6">Attributes:</Typography>
          {loading ?
            <>
              <Skeleton variant="rounded" height={30} sx={{ mt: 2 }} />
              <Skeleton variant="rounded" height={30} sx={{ mt: 2 }} />
              <Skeleton variant="rounded" height={30} sx={{ mt: 2 }} />
              <Skeleton variant="rounded" height={30} sx={{ mt: 2 }} />
            </>
            : sidebarItems.length > 0 ?
              Object.values(sidebarItems).map((sideBarItem, index) => (
                <SideBarItem key={sideBarItem.id} data={sideBarItem} />
              ))
              :
              Object.values(SIDEBAR_ITEMS).map((sideBarItem, index) => (
                <SideBarItem key={sideBarItem.id} data={sideBarItem} />
              ))}
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }}
            onClick={handleOpen}
          >
            Upload Data
          </Button>
        </Box>

        <Box p={3} borderRadius={3} mt={3} bgcolor={'white'} >
          <Typography variant="h6">Components:</Typography>
          {Object.values(sidebarComponents).map((sidebarComponent, index) => (
            <SideBarItem key={sidebarComponent.id} data={sidebarComponent} />
          ))}
        </Box>

      </CustomStack>
      <div className="pageContainer">
        {!loading ? <Box px={3} borderRadius={3} bgcolor={'white'} mx={3} minHeight={'85vh'} >
          {layout.map((row, index) => {
            const currentPath = `${index}`
            return (
              <React.Fragment key={row.id}>
                {renderRow(row, currentPath)}
              </React.Fragment>
            )
          })}
        </Box>
          : <Box p={3} borderRadius={3} bgcolor={'white'} mx={3} minHeight={'85vh'} >
            <Skeleton variant="rectangle" width={'305px'} height={'650px'} sx={{ mx: 'auto' }} />
          </Box>}

        <TrashDropZone
          data={{
            layout
          }}
          onDrop={handleDropToTrashBin}
        />
      </div>
      <CustomStack >
        <Box p={3} borderRadius={3} bgcolor={'white'}>
          <EditSidebar titles={titles} />
        </Box>
        <Box p={3} borderRadius={3} bgcolor={'white'} mt={3}>
          <ErrorBox />
          {saving ? <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }}
            disabled
          >
            <CircularProgress size={25} />
          </Button> :
            <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }} onClick={handleUpdateLayout}>Save Changes</Button>}
          <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }}>Preview</Button>
          <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%', mt: 3 }}>Generate</Button>
        </Box>

      </CustomStack>
      <AlertBox message={"Saved successfully"} />
    </div>
  )
}
export default Design
