import React from 'react'
import Navbar from './Navbar'
import { Navigate, useLocation } from 'react-router-dom'

function Layout({ children }) {
    const { pathname } = useLocation()

    const isAuthenticated = () => {
        return !!localStorage.getItem("access_token")
    }


    return (
        <div>
            {
                pathname == '/login' || pathname == '/signup' ? [children]
                    : isAuthenticated() ? <Navbar children={children} />
                        : <Navigate to={'/login'} />
            }
        </div>
    )
}

export default Layout