import React, { useEffect, useState } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Box from "@mui/material/Box"
import { Button, Skeleton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import NewProjectModal from "../components/modals/NewProjectModal"
import { getUserProjects } from "../services/api"
import { useStateStore } from "../stores/useStateStore"
import ErrorBox from "../components/ErrorBox"
import initialData from '../initial-data'
import { useObjectStore } from "../stores/useObjectStore"

const initialLayout = initialData.layout

export default function Projects() {

    const [projects, setProjects] = useState(null)
    const [openProjectModal, setOpenProjectModal] = useState(false)
    const handleOpenProjectModal = () => setOpenProjectModal(true)
    const handleCloseProjectModal = () => setOpenProjectModal(false)
    const { setErrorMessage, loading, setLoading } = useStateStore()
    const { setLayout } = useObjectStore()

    const navigate = useNavigate()

    useEffect(() => {
        setErrorMessage('')
        setLoading(true)
        getUserProjects()
            .then((res) => {
                setProjects(res?.data?.projects)
                setLayout(initialLayout)
                setLoading(false)
                if (res.status != 200) {
                    setErrorMessage(res?.response?.data?.message)
                    return
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message)
                    return
                }
                if (err.request) {
                    setErrorMessage(err?.message)
                    return
                }
                console.log(err)
            })
    }, [])



    return (
        <>
            <ErrorBox />
            <TableContainer component={Paper} sx={{ p: 3 }}>
                <Box
                    m={1} //margin
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography variant="h6">Projects</Typography>
                    {/* <Button variant="outlined" sx={{textTransform: 'capitalize'}} onClick={() => navigate('/')}>Create design file</Button> */}
                    <Button variant="outlined" sx={{ textTransform: 'capitalize' }} onClick={handleOpenProjectModal}>Create Project</Button>

                </Box>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ fontWeight: 'bold', color: 'gray' }}>Name</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', color: 'gray' }}>Data File</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', color: 'gray' }}>Number of Tags</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', color: 'gray' }}>Tags Generated</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', color: 'gray' }}>Status</TableCell>
                        </TableRow>
                    </TableHead>

                    {loading ?
                        <TableBody>
                            <TableRow
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                                <TableCell><Skeleton variant="text" height={20} /></TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {projects?.map((row) => (
                                <TableRow
                                    key={row.project_id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, ":hover": { bgcolor: '#f0f0f0', cursor: 'pointer' } }}
                                    onClick={() => navigate(`/design/${row.project_id}`)}
                                >
                                    <TableCell sx={{ fontSize: 11 }} align="left">{row.name}</TableCell>
                                    <TableCell sx={{ fontSize: 11 }} align="left">{row.original_filename}</TableCell>
                                    <TableCell sx={{ fontSize: 11 }} align="left">{row.number_of_tags}</TableCell>
                                    <TableCell sx={{ fontSize: 11 }} align="left">{row.tags_generated}</TableCell>
                                    <TableCell sx={{ fontSize: 11, textTransform: 'capitalize' }} align="left">{row.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <NewProjectModal open={openProjectModal} handleClose={handleCloseProjectModal} />
        </>
    );
}