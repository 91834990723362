import React from 'react'
import { useStateStore } from '../stores/useStateStore'
import { Typography } from '@mui/material'

function ErrorBox() {

    const { errorMessage } = useStateStore()

    return (
        <>
            {errorMessage ? <Typography color={'red'} p={2} bgcolor={'antiquewhite'} borderRadius={3} mb={2}>
                {errorMessage}
            </Typography> : null}
        </>
    )
}

export default ErrorBox