import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import gradient from '../assets/gradient.png'
import whitelogo from '../assets/whitelogo.png'

function BgPlaceholder() {
    return (
        <Box
            width={'50%'}
            display={{ xs: 'none', lg: 'flex' }}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100vh'}
            sx={{ backgroundColor: 'lightgray', borderTopLeftRadius: 80, borderBottomLeftRadius: 80, overflow: 'hidden' }}>
            <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
                height={'100%'}
                sx={{ backgroundImage: `url('${gradient}')`, backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat' }}
                position={'relative'}
            >
                <Box
                    component={'img'}
                    src={whitelogo}
                    width={200}
                    position={'absolute'}
                    bottom={25}
                    right={25}
                />
                <Box px={10} pb={10} color={'white'} textAlign={'center'}>
                    <Typography variant='h1' >
                        Welcome!
                    </Typography>
                    <Typography mt={2}>Enter your credentials to access your account</Typography>
                </Box>
            </Stack>
        </Box>
    )
}

export default BgPlaceholder