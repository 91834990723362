import axios from "axios"

const { REACT_APP_API_URL } = process.env

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 60000,
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token")
    if (token) {
      config.headers["Authorization"] = `${token}`
    }
    return config
  },
  (error) => {
    console.log("api error: ", error)
    const statusCode = error.response ? error.response.status : null
    if (statusCode === 401) {
      localStorage.removeItem("access_token")
      window.location.href = "/login"
    }
    return Promise.reject(error)
  }
)



// API requests for User Authentication

export const getUsers = async () => {
  return await api.get(`api/users`)
}

export const logoutUser = async () => {
  return await api
    .delete(`auth/logout`)
    .then(() => {
      localStorage.removeItem("access_token")
    })
    .catch((error) => {
      console.log(error)
    })
}

export const checkUserLoggedIn = async () => {
  return await api.post(`auth/is_logged_in`)
}



// API Services for User Management
// POST Users with email, password and confirm_password
export const createUser = async (signupInfo) => {
  return await api.post(`api/users`, signupInfo)
}

export const loginUser = async (loginInfo) => {
  return await api.post(`auth/login`, loginInfo )
}

// Get Information on one single user takes as input the userId
export const getSingleUser = async (userId) => {
  return await api.get(`api/users/${userId}`)
}

// Patch single user information
export const updateUser = async (userId, role, is_marked_revoke) => {
  return await api.patch(`api/users/${userId}`, {
    role,
    is_marked_revoke,
  })
}

export const createProject = async (formData) => {
  return await api.post(`api/projects`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
})
}

export const getUserProjects = async () => {
  return await api.get(`api/projects`)
}

export const getProject = async (projectId) => {
  return await api.get(`api/project/${projectId}`)
}

export const updateLayout = async (projectId, layout) => {
  return await api.patch(`api/project/${projectId}`, {layout})
}