import React, { useContext, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "../constants";
import { Box, Typography } from "@mui/material";
import { useObjectStore } from '../stores/useObjectStore'

const style = {
  // border: "1px dashed black",
  // padding: "0.5rem 1rem",
  // backgroundColor: "white",
  cursor: "move"
};
const Component = ({ data, components, path }) => {
  const ref = useRef(null);
  const { layout, setLayout, selectedComponent, setSelectedComponent, labelAndData, names, setNames } = useObjectStore()

  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: {
      type: COMPONENT,
      id: data?.id,
      path
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);


  const component = components[data?.id];

  const updateEditAtrr = (id) => {
    setLayout(layout.map(row => {
      return {
        ...row,
        children: row.children.map(column => {
          return {
            ...column,
            children: column.children.map(comp => {
              if (comp?.id === id) {
                return {
                  ...comp,
                  name: data?.name,
                  data: data?.data,
                  label: data?.label,
                  type: data?.type
                };
              }
              return comp
            })
          };
        })
      };
    }))
    setSelectedComponent(component.id)
  }

  const pathItems = path.split('-')
  const index = pathItems[pathItems.length - 1]

  useEffect(() => {
    setNames([...names, data?.name])
  }, [layout])

  // console.log("Names: ", names)


  return (
    <div
      onClick={() => setSelectedComponent(component.id)}
      ref={ref}
      style={{ ...style, opacity }}
      className="component draggable"
      id="componentBox"
    >
      <Box
        sx={{ backgroundImage: `url(${data?.image?.src})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
        border={'gray 1px dashed'} display={'flex'} justifyContent={'center'} alignItems={'center'} mx={'auto'} px={1} width={data?.width} minHeight={data?.height}
      >
        {
          data?.componentType == "Blank" ? <Typography></Typography>
            : data?.image?.src ? <Typography></Typography>
              : data?.componentType == "Image" ? <Typography fontSize={`${data?.fontSize}px`}>{data?.label}</Typography>
                : labelAndData[data?.data?.trim().toLowerCase()] ? <Typography fontSize={`${data?.fontSize}px`}>{data?.label}{labelAndData[data?.data?.trim().toLowerCase()]}</Typography>
                  : labelAndData[component?.type?.split(":")[1].trim().toLowerCase()] ? <Typography fontSize={`${data?.fontSize}px`}>{data?.label}: {labelAndData[component?.type?.split(":")[1].trim().toLowerCase()]}</Typography>
                    : <Typography fontSize={`${data?.fontSize}px`}>{data?.label}</Typography>
        }
      </Box>
    </div>
  );
};
export default Component;
