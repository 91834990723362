import React, { useEffect, useRef, useState } from 'react'
import { TextField, Typography, Divider, Box, Button, Autocomplete } from '@mui/material'
import { useObjectStore } from '../stores/useObjectStore';
import barcode from '../assets/barcode.png'
import qr from '../assets/qr.png'


const EditSidebar = ({titles}) => {

    const data = titles?.map(title => title.toLowerCase())
    const types = ["Barcode", "Blank", "Image", "Text", "QR Code"]

    const { layout, setLayout, selectedComponent } = useObjectStore()
    const [attrProps, setAttrProps] = useState({})
    const [inputValue, setInputValue] = useState('')
    const [inputValue2, setInputValue2] = useState('')

    useEffect(() => {
        layout.map(row => {
            row.children.map(column => {
                column.children.map(component => {
                    if (component?.id === selectedComponent) {
                        setAttrProps({ ...component, ...attrProps })
                    }
                })
            })
        })


    }, [selectedComponent])

    const handleChange = (e) => {
        const { name, value } = e.target
        setAttrProps({
            ...attrProps,
            [name]: value
        })
    }

    const handleLogoChange = (e) => {
        const { files } = e.target
        if (files) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setAttrProps({
                    ...attrProps,
                    image: { src: reader.result }
                })
            }
            reader.readAsDataURL(files[0])
        }
    }

    const handleAutocompleteChange = (event, newValue, field) => {
        setAttrProps(prev => ({
            ...prev,
            [field]: newValue,
        }));
    };

    const applyDimensions = () => {
        const newLayout = layout.map(row => {
            return {
                ...row,
                children: row.children.map(column => {
                    return {
                        ...column,
                        children: column.children.map(component => {
                            if (component?.id === selectedComponent) {
                                if (attrProps.componentType == "QR Code") {
                                    return {
                                        ...component,
                                        ...attrProps,
                                        height: parseInt(attrProps?.height),
                                        width: parseInt(attrProps?.width),
                                        image: {src: qr}
                                    }
                                }
                                if (attrProps.componentType == "Barcode") {
                                    return {
                                        ...component,
                                        ...attrProps,
                                        height: parseInt(attrProps?.height),
                                        width: parseInt(attrProps?.width),
                                        image: {src: barcode}
                                    }
                                }
                                if (attrProps.componentType == "Image") {
                                    return {
                                        ...component,
                                        ...attrProps,
                                        height: parseInt(attrProps?.height),
                                        width: parseInt(attrProps?.width),
                                        image: {src: attrProps.image.src}
                                    }
                                }
                                return {
                                    ...component,
                                    ...attrProps,
                                    height: parseInt(attrProps?.height),
                                    width: parseInt(attrProps?.width),
                                    image: {src: ''}
                                }
                            }
                            return component 
                        })
                    };
                })
            };
        });

        setLayout(newLayout)
    }

    

    return (
        <div style={{ padding: 16 }}>
            <Typography variant="h6" mb={1}>Attribute Properties:</Typography>
            <Typography variant="subtitle1">Name</Typography>
            <TextField
                value={attrProps?.name}
                disabled
                name='name'
                onChange={handleChange}
                fullWidth
                variant="outlined"
                size='small'
                sx={{ mb: 2 }}
            />
            <Typography variant="subtitle1">Type</Typography>
            <Autocomplete
                id="type-autocomplete"
                options={types}
                value={attrProps?.componentType || null}
                name='componentType'
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, "componentType")}
                inputValue={inputValue2}
                onInputChange={(event, newInputValue) => {
                    setInputValue2(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        size='small'
                        sx={{ mb: 2 }}
                    />
                )}
            />
            <Typography variant="subtitle1">Label</Typography>
            <TextField
                value={attrProps?.label}
                fullWidth
                variant="outlined"
                size='small'
                sx={{ mb: 2 }}
                name='label'
                onChange={handleChange}
            />
            <Typography variant="subtitle1">Data</Typography>
            <Autocomplete
                id="combo-box"
                options={data}
                value={attrProps?.data || null}
                name='data'
                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, "data")}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        size='small'
                        sx={{ mb: 2 }}
                    />
                )}
            />
            {attrProps?.componentType == 'Image' &&
                <Box>
                    <Typography variant="subtitle1">File</Typography>
                    <TextField
                        name='logo'
                        onChange={handleLogoChange}
                        fullWidth
                        variant="outlined"
                        size='small'
                        type={'file'}
                    />
                </Box>}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" mb={1}>Properties:</Typography>
            <Typography variant="subtitle1">Height</Typography>
            <TextField
                value={attrProps?.height}
                name='height'
                onChange={handleChange}
                fullWidth
                variant="outlined"
                size='small'
                sx={{ mb: 2 }}
                type='number'
            />
            <Typography variant="subtitle1">Width</Typography>
            <TextField
                value={attrProps?.width}
                name='width'
                onChange={handleChange}
                fullWidth
                variant="outlined"
                size='small'
                sx={{ mb: 2 }}
                type='number'
            />
            {attrProps?.componentType == 'Text' &&
                <Box>
                    <Typography variant="subtitle1">Font Size</Typography>
                    <TextField
                        value={attrProps?.fontSize}
                        name='fontSize'
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        size='small'
                        sx={{ mb: 2 }}
                        type='number'
                    />
                </Box>}
            <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%' }}
                onClick={applyDimensions}
            >Apply Changes</Button>
        </div>
    );
};

export default EditSidebar
