import { create } from 'zustand'
import initialData from '../initial-data';

const initialLayout = initialData.layout;
const initialComponents = initialData.components;

export const useObjectStore = create((set) => ({
    selectedComponent: '',
    setSelectedComponent: (newValue) => set({selectedComponent: newValue}),
    layout: initialLayout,
    setLayout: (newValue) => set({layout: newValue }),
    components: initialComponents,
    setComponents: (newValue) => set({components: newValue }),
    labelAndData: {},
    setLabelAndData: (newValue) => set({labelAndData: newValue}),
    canvasSize: {},
    setCanvasSize: (newValue) => set({canvasSize: newValue}),
    names: [],
    setNames: (newValue) => set({names: newValue})

}))