import React from "react";
import { useDrag } from "react-dnd"
import { SIDEBAR_ITEM } from "../constants"
import { Box } from "@mui/material";

const SideBarItem = ({ data }) => {
  // console.log("Data: ", data)
  const [{ opacity }, drag] = useDrag({
    type: SIDEBAR_ITEM,
    item: data,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  return (
    <div ref={drag} style={{ opacity }}>
      <Box border={"#D0D5DD 1px solid"} borderRadius={2} py={1} px={3} bgcolor={'white'} mt={2}>
        {data.component.type}
      </Box>
    </div>
  );
};
export default SideBarItem;
