import React from 'react'
import { Box, Modal, Typography, Button, TextField, Stack } from '@mui/material'
import { useObjectStore } from '../../stores/useObjectStore'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '25%',
    transform: 'translate(-50%, -50%)',
    // width: "40%",
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    ':focus-visible': { outline: 'none' }
}

const EditCanvasSizeModal = ({ open, handleClose, setCanvasSize, canvasSize }) => {

    const { layout, setLayout } = useObjectStore()

    const handleChange = (e) => {
        const { name, value } = e.target
        setCanvasSize({
            ...canvasSize,
            [name]: value
        })
    }


    const applyDimensions = () => {
        const newLayout = layout.map(row => {
            return {
                ...row,
                children: row.children.map(column => {
                    return {
                        ...column,
                        height: parseInt(canvasSize?.height),
                        width: parseInt(canvasSize?.width),
                        padding: parseInt(canvasSize?.padding)
                    };
                })
            };
        });

        setLayout(newLayout)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="upload-modal-title"
            aria-describedby="upload-modal-description"
        >
            <Box sx={style} display={'flex'} flexDirection={'column'} alignItems={'end'} >
                <Box width={'100%'}>
                    <Typography variant="h6" mb={1}>Canvas Size</Typography>
                    <Stack>
                        <Box>
                            <Typography variant="subtitle1">Height</Typography>
                            <TextField
                                value={canvasSize?.height}
                                name='height'
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                size='small'
                                sx={{ mb: 2 }}
                                type='number'
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1">Width</Typography>
                            <TextField
                                value={canvasSize?.width}
                                fullWidth
                                variant="outlined"
                                size='small'
                                sx={{ mb: 2 }}
                                name='width'
                                onChange={handleChange}
                                type='number'
                            />
                        </Box>
                    <Box>
                        <Typography variant="subtitle1">Padding</Typography>
                        <TextField
                            value={canvasSize?.padding}
                            fullWidth
                            variant="outlined"
                            size='small'
                            sx={{ mb: 2 }}
                            name='padding'
                            onChange={handleChange}
                            type='number'
                        />
                    </Box>
                    </Stack>
                    <Button variant="contained" sx={{ textTransform: 'capitalize', color: 'white', width: '100%' }}
                        onClick={applyDimensions}
                    >Apply Changes</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default EditCanvasSizeModal
