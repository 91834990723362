import './App.css'
import Layout from './components/Layout'
import { createTheme, ThemeProvider } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Login from './pages/login'
import Signup from './pages/signup'
import Projects from './pages/projects'
import Design from './pages/design'


function App() {

  const mdTheme = createTheme({
    palette: {
      primary: {
        main: '#56B7AF'
      }
    }
  })

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={mdTheme}>
          <Layout>
            <Routes>
              <Route path='/' element={<Projects />} />
              <Route path='/design/:id' element={<Design />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/login' element={<Login />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='*' element={<p>404 <br /> Nothing Found</p>} />
            </Routes>
          </Layout>
        </ThemeProvider>
      </DndProvider>
    </>
  )
}

export default App
