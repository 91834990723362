import { COMPONENT, ROW, COLUMN } from "./constants";
import barcode from './assets/barcode.png'
import qr from './assets/qr.png'

const initialData = {
  layout: [
    {
      type: ROW,
      id: "row0",
      children: [
        {
          type: COLUMN,
          id: "column0",
          width: 305,
          height: 650,
          padding: 5,
          children: [
            // {
            //   type: COMPONENT,
            //   id: "component0",
            //   width: 150,
            //   height: 150,
            //   // name: "Image 1",
            //   label: "Logo",
            //   data: "logo",
            //   image: { src: '' }
            // },
            // {
            //   type: COMPONENT,
            //   id: "component1",
            //   width: 300,
            //   height: 50,
            //   // name: "Text 1",
            //   label: "Scan Here",
            //   data: "none",
            // },
            // {
            //   type: COMPONENT,
            //   id: "component2",
            //   width: 300,
            //   height: 300,
            //   // name: "QR Code 1",
            //   label: "QR Code",
            //   data: "qrcode",
            //   image: { src: qr }
            // },
            // {
            //   type: COMPONENT,
            //   id: "component3",
            //   width: 300,
            //   height: 20,
            //   // name: "Text 2",
            //   label: "Pin",
            //   data: "pin"
            // },
            // {
            //   type: COMPONENT,
            //   id: "component4",
            //   width: 300,
            //   height: 20,
            //   // name: "Text 3",
            //   label: "Puk",
            //   data: "puk"
            // },
            // {
            //   type: COMPONENT,
            //   id: "component5",
            //   width: 300,
            //   height: 20,
            //   // name: "Text 4",
            //   label: "Short Code",
            //   data: "shortcode"
            // },
            // {
            //   type: COMPONENT,
            //   id: "component6",
            //   width: 300,
            //   height: 70,
            //   // name: "Barcode 1",
            //   label: "Barcode",
            //   data: "barcode",
            //   image: { src: barcode }
            // }
          ]
        },
        // {
        //   type: COLUMN,
        //   id: "column1",
        //   children: [
        //     {
        //       type: COMPONENT,
        //       id: "component2"
        //     }
        //   ]
        // }
      ]
    },
    // {
    //   type: ROW,
    //   id: "row1",
    //   children: [
    //     {
    //       type: COLUMN,
    //       id: "column2",
    //       children: [
    //         {
    //           type: COMPONENT,
    //           id: "component3"
    //         },
    //         {
    //           type: COMPONENT,
    //           id: "component0"
    //         },
    //         {
    //           type: COMPONENT,
    //           id: "component2"
    //         }
    //       ]
    //     }
    //   ]
    // }
  ],
  components: {
    component0: { id: "component0", type: "Image: Logo", content: "Image: Logo" },
    component1: { id: "component1", type: "Text: Scan Here", content: "Text: Scan Here" },
    component2: { id: "component2", type: "QR Code: QR Code", content: "QR Code: QR Code" },
    component3: { id: "component3", type: "Text: Pin", content: "Text: Pin" },
    component4: { id: "component4", type: "Text: Puk", content: "Text: Puk" },
    component5: { id: "component5", type: "Text: Short Code", content: "Text: Short Code" },
    component6: { id: "component6", type: "Barcode: Barcode", content: "Barcode: Barcode" },
    // component7: { id: "component7", type: "Text: Barcode id", content: "Text: Barcode id" },
    // component2: { id: "component2", type: "email", content: "Some email" },
    // component3: { id: "component3", type: "name", content: "Some name" },
    // component4: { id: "component4", type: "phone", content: "Some phone" }
  }
};

export default initialData;
