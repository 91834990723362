import React from 'react'
import { useStateStore } from '../stores/useStateStore'
import { Alert, AlertTitle } from '@mui/material'

function AlertBox({message}) {

    const {alert} = useStateStore()

    return (
        <>
            {alert ?
                <Alert severity="success" sx={{ px: 5, position: 'absolute' }}>
                    <AlertTitle>{message}</AlertTitle>
                </Alert>
                : null
            }
        </>
    )
}

export default AlertBox