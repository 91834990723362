import shortid from "shortid";
import barcode from './assets/barcode.png'
import qr from './assets/qr.png'

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const SIDEBAR_ITEMS = [
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "Text: Pin",
  //     content: "Text: Pin",
  //     width: 300,
  //     height: 20,
  //     // name: "Text 1",
  //     label: "Pin",
  //     data: "pin"
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "Text: Puk",
  //     content: "Text: Puk",
  //     width: 300,
  //     height: 20,
  //     // name: "Text 1",
  //     label: "Puk",
  //     data: "puk"
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "QR Code: QR Code",
  //     content: "QR Code: QR Code",
  //     width: 300,
  //     height: 300,
  //     // name: "QR Code 1",
  //     label: "QR Code",
  //     data: "qrcode",
  //     image: {src: qr}
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "Bar Code: Bar Code",
  //     content: "Bar Code: Bar Code",
  //     width: 300,
  //     height: 70,
  //     // name: "Barcode 1",
  //     label: "Barcode",
  //     data: "barcode",
  //     image: {src: barcode}
  //   }
  // }
];
